import React, { useEffect } from "react"
import Img from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import classes from "./index.module.scss"

import ContactInline from "../components/contactInline"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} lang={intl.locale} />
      <section className={classes.hero}>
        <div className={classes.hero__container}>
          <div
            className={`${classes.hero__img} ${"animate"}`}
            data-animation="paralax"
            data-speed=".08"
          >
            <Img alt="zdjecie naglowku" filename="hero-main-img.png"></Img>
          </div>

          <div className={classes.hero__textbox}>
            <div className={classes.hero__textbox__text}>
              <h1 className="main-heading">
                <FormattedMessage id="heading" />
                <span className="main-heading--sub">
                  <FormattedMessage id="heading2" />
                </span>
              </h1>
              <p>
                Web design & development, UX/UI, e-commerce, web apps -
                spersonalizowane i dopasowane strony internetowe dla każdego
              </p>
            </div>
          </div>
          <div className={classes.hero__textbox__btnbox}>
            <a
              href="#technologia"
              className="btn animate"
              data-animation="neo-background-btn"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <FormattedMessage id="btn" />
            </a>
            {/* <a className="btn--callout">PROJEKTY</a> */}
          </div>
        </div>
      </section>
      <section className={classes.technologia} id="technologia">
        <h2
          style={{ textAlign: "center", gridColumn: "center-start/center-end" }}
        >
          <FormattedMessage id="technologyHeading" />
        </h2>
        <div className={classes.technologia__hexagon_container}>
          <div
            className={`${classes.technologia__hexagon} ${"animate"}`}
            data-animation="neo-background-2"
          >
            <img
              className={`${classes.technologia__hexagon__icon} animate`}
              data-animation="fade"
              src={require("../images/technologia-icon-1.svg")}
              alt="hero-img"
            ></img>
            <p>
              <FormattedMessage id="technologyHexagon1" />
            </p>
          </div>

          <div
            className={`${classes.technologia__hexagon} ${"animate"}`}
            data-animation="neo-background-2"
          >
            <img
              className={`${classes.technologia__hexagon__icon} animate`}
              data-animation="fade"
              src={require("../images/technologia-icon-2.svg")}
              alt="hero-img"
            ></img>
            <p>
              <FormattedMessage id="technologyHexagon2" />
            </p>
          </div>

          <div
            className={`${classes.technologia__hexagon} ${"animate"}`}
            data-animation="neo-background-2"
          >
            <img
              className={`${classes.technologia__hexagon__icon} animate`}
              data-animation="fade"
              src={require("../images/technologia-icon-3.svg")}
              alt="hero-img"
            ></img>
            <p>
              <FormattedMessage id="technologyHexagon3" />
            </p>
          </div>

          <div
            className={`${classes.technologia__hexagon} ${"animate"}`}
            data-animation="neo-background-2"
          >
            <img
              className={`${classes.technologia__hexagon__icon} animate`}
              data-animation="fade"
              src={require("../images/technologia-icon-4.svg")}
              alt="hero-img"
            ></img>
            <p>
              <FormattedMessage id="technologyHexagon4" />
            </p>
          </div>
        </div>
      </section>
      <section className={classes.niepowtarzalnosc}>
        <h2
          style={{ textAlign: "center", gridColumn: "center-start/center-end" }}
        >
          <FormattedMessage id="niepowtarzalnoscHeading" />
        </h2>
        <div className={classes.niepowtarzalnosc__container}>
          <div className={classes.niepowtarzalnosc__container__img}>
            <Img
              alt="grafika sekcji "
              filename="niepowtarzalnosc_img.png"
            ></Img>
          </div>

          <div className={classes.niepowtarzalnosc__container__textbox}>
            <p>
              <FormattedMessage id="niepowtarzalnoscText1" />
            </p>
            <p>
              <FormattedMessage id="niepowtarzalnoscText2" />
            </p>
          </div>
        </div>
      </section>
      <section className={classes.projekty}>
        <h2 style={{ textAlign: "center" }}>
          <FormattedMessage id="projectsHeading" />
        </h2>
        <div className={classes.projekty__itembox}>
          <Link
            to="/projects/project/Habas"
            className={`${classes.projekty__itembox__item} ${"animate"}`}
            data-animation="neo-background-1"
          >
            <div
              className={`${classes.projekty__itembox__item_img} ${"animate"}`}
              data-animation="fade"
            >
              <Img alt="zdjecie projektu" filename="gallery-catbud.png"></Img>
            </div>

            <div className={classes.projekty__itembox__item__textbox}>
              <p className={classes.projekty__itembox__item__subheading}>
                HABAS
              </p>
              <p className={classes.projekty__itembox__item__heading}>
                <FormattedMessage id="projectSubheading1" />
              </p>
            </div>
          </Link>
          <Link
            to="/projects/project/nova7"
            className={`${classes.projekty__itembox__item} ${"animate"}`}
            data-animation="neo-background-1"
          >
            <div
              className={`${classes.projekty__itembox__item_img} ${"animate"}`}
              data-animation="fade"
            >
              <Img alt="zdjecie projektu" filename="nova7mockup.png"></Img>
            </div>
            <div className={classes.projekty__itembox__item__textbox}>
              <p className={classes.projekty__itembox__item__subheading}>
                NOVA7
              </p>
              <p className={classes.projekty__itembox__item__heading}>
                <FormattedMessage id="projectSubheading2" />
              </p>
            </div>
          </Link>
          <Link
            to="/projects/project/Alan Tańczy"
            className={`${classes.projekty__itembox__item} ${"animate"}`}
            data-animation="neo-background-1"
          >
            <div
              className={`${classes.projekty__itembox__item_img} ${"animate"}`}
              data-animation="fade"
            >
              <Img alt="zdjecie projektu" filename="gallery-alan.png"></Img>
            </div>
            <div className={classes.projekty__itembox__item__textbox}>
              <p className={classes.projekty__itembox__item__subheading}>
                Alantańczy
              </p>
              <p className={classes.projekty__itembox__item__heading}>
                <FormattedMessage id="projectSubheading3" />
              </p>
            </div>
          </Link>
        </div>
        <Link
          to="/projects"
          className="btn animate"
          data-animation="neo-background-btn"
        >
          <FormattedMessage id="btn" />
        </Link>
      </section>

      <ContactInline></ContactInline>
    </Layout>
  )
}

export default IndexPage
