import React from "react"
import classes from "./contactInline.module.scss"

import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

const ContactInline = () => {
  return (
    <section className={classes.contact}>
      <h2
        style={{ textAlign: "center", gridColumn: "center-start/center-end" }}
      >
        <FormattedMessage id="contactHeading" />
      </h2>
      <div className={classes.contact__textbox}>
        <p className={classes.contact__textbox__text}>
        <FormattedMessage id="contactText" />
        </p>
      </div>
      <div className={classes.contact__linkbox}>
        <ul className={classes.contact__linkbox__links}>
          <li className={classes.contact__linkbox__links__link}>
            <a
              href="https://www.facebook.com/pawelstyczendesign/"
              target="blank"
            >
              <img src={require("../images/facebook.svg")}></img>
            </a>
          </li>
          <li className={classes.contact__linkbox__links__link}>
            {" "}
            <a
              href="https://www.instagram.com/pawelstyczenuiux/"
              target="blank"
            >
              <img src={require("../images/instagram.svg")}></img>
            </a>
          </li>
          <li className={classes.contact__linkbox__links__link}>
            {" "}
            <a
              href="https://www.linkedin.com/in/paweł-styczeń-12aa93147"
              target="blank"
            >
              <img src={require("../images/linkedin.svg")}></img>
            </a>
          </li>
        </ul>
      </div>

      <div className={classes.contact__btn}>
        <div
          className="btn--send animate"
          data-animation="neo-background-btn--send"
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/contact"
          >
            <FormattedMessage id="btn--contact" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ContactInline
